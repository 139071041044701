import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SmallWrapper from "../common/SmallWrapper/SmallWrapper"
import img from "../../assets/img/team.jpg"

const Uber = () => {
    const { t } = useTranslation();
    return (
        <>
            <SmallWrapper id="about">
                <p className='common-heading'>{t("section-subheading-about")}</p>
                <h1 className='common-text'>{t("section-heading-about")}</h1>
            </SmallWrapper>

            <Row className="about-us pt-5 pt-sm-0">
                <div className='offset-md-2 col-md-5'>
                    <img src={img} className='img-fluid'/>
                </div>
                <div className='col-md-4'>
                    <p>{t("content-about-1-para")}</p>
                    <p>{t("content-about-2-para")}</p>
                </div>
            </Row>
        </>
    )
}

export default Uber