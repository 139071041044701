import SmallWrapper from "../common/SmallWrapper/SmallWrapper"
import { useTranslation } from 'react-i18next';
import "./Header.css"

const Header = () => {
    const { t } = useTranslation();
    return (
        <>
        <SmallWrapper id="home">
            <div className='header'>
                <div className="row">
                    <div className="col-md-11">
                    <p> {t("header-heading")}</p>
                    <h1> {t("header-text")} </h1>
                    </div>
                </div>
            </div>
        </SmallWrapper>
          </>   )

}

export default Header