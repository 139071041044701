import SmallWrapper from "../common/SmallWrapper/SmallWrapper"
import { useTranslation } from 'react-i18next';
import "./Section.css"

const Section = () => {
    const { t } = useTranslation();
    return (
        <SmallWrapper id="services">
            <div className="row">
                <div className="col-md-9">
                    <p className='common-heading'>{t("section-subheading-services")}</p>
                    <h2 className='common-text '>{t("section-heading-services")}</h2>
                </div>
            </div>
            <div className='row pt-5 pt-sm-0'>
                <div className='col-md-4'>
                    <h4>{t("content-services-1-heading")}</h4>
                    <p>{t("content-services-1-para")}</p>
                </div>
                <div className='col-md-4'>
                    <h4>{t("content-services-2-heading")}</h4>
                    <p>{t("content-services-2-para")}</p>
               </div>
                <div className='col-md-4'>
                    <h4>{t("content-services-3-heading")}</h4>
                    <p>{t("content-services-3-para")}</p>
               </div>
            </div>
        </SmallWrapper>
    )

}

export default Section