import React, { useState } from "react";
import SmallWrapper from "../common/SmallWrapper/SmallWrapper"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./Contact.css"
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    const [name, setName] = useState("");
    const [addr, setAddr] = useState("");
    const [msg, setMsg] = useState("");
    const [nameError, setNameError] = useState(false);
    const [addrError, setAddrError] = useState(false);
    const [msgError, setMsgError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    const invalidStyle = {
        width: "100%",
        marginTop: ".25rem",
        fontSize: ".875em",
        color: "#dc3545"
    };

    const validStyle = {
        display: "none"
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleSend = () => {

        var hasError = false;
        setNameError(false);
        setAddrError(false);
        setMsgError(false);

        if (name.length <= 0) {
            setNameError(true);
            hasError = true;
        }

        var emailErr = !validateEmail(addr);

        if (emailErr) {
            setAddrError(true);
            hasError = true;
        }

        if (msg.length <= 0) {
            setMsgError(true);
            hasError = true;
        }

        if (hasError) {
            console.log("error")
        } else {
            fetch(`/script.cgi?firstname=${encodeURIComponent(name)}&lastname=dummy&email=${encodeURIComponent(addr)}&message=${encodeURIComponent(msg)}`);

            setName("");
            setAddr("");
            setMsg("");

            setShowModal(true);
        }
    };

    return (
        <Container>
            <Modal show={showModal} onHide={handleClose} centered size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Message send</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your message was send, we get in touch with you as soon as possible!</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <SmallWrapper id="contact">
                <div className="contact-us">
                    <p className='common-heading '>{t("section-subheading-contact")}</p>
                    <h1 className='common-text'>{t("section-heading-contact")}</h1>

                    <div className="row form-row">
                        <div className="col-md-6">
                            <label htmlFor="">{t("contact-form-name")} *</label>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} id="cont-name" className="form-control" placeholder={t("contact-form-name-placeholder")} />
                            <div id="nameFeedback" style={nameError ? invalidStyle : validStyle}>
                                Please provide a valid name.
                            </div>
                            <div className="contact-spacer"></div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="">{t("contact-form-email")} *</label>
                            <input type="text" value={addr} onChange={(e) => setAddr(e.target.value)} id="cont-addr" className="form-control" placeholder={t("contact-form-email-placeholder")} />
                            <div id="addrFeedback" style={addrError ? invalidStyle : validStyle}>
                                Please provide a valid email.
                            </div>
                            <div className="contact-spacer"></div>
                        </div>
                    </div>
                    <div className="row form-bottom-row">
                        <div className="col-md-6">
                            <label htmlFor="">{t("contact-form-message")}</label>
                            <textarea value={msg} onChange={(e) => setMsg(e.target.value)} id="cont-msg" rows={1} className="form-control resizeable" placeholder={t("contact-form-message-placeholder")}></textarea>
                            <div id="msgFeedback" style={msgError ? invalidStyle : validStyle}>
                                Please provide a message.
                            </div>
                            <div className="contact-spacer"></div>
                        </div>
                        <div className="col-md-6 d-grid align-items-end">
                            <button onClick={handleSend} className='btn btn-success'>{t("contact-form-send-btn-text")}</button>
                        </div>
                        <div className="contact-spacer"></div>
                    </div>
                </div>
            </SmallWrapper>
        </Container>
    )
}

export default Contact
