import SmallWrapper from "../common/SmallWrapper/SmallWrapper"
import "./Footer.css"
import {AiFillLinkedin} from "react-icons/ai"
import {AiFillGithub} from "react-icons/ai"
import { IoLogoXing } from 'react-icons/io';
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next';

const Footer = ({showPriv}) => {
    const { t } = useTranslation();

    const privHandler = () => {
    	showPriv();
  	}

    return (
        <div className="footerBlur">
        <Container>
        <SmallWrapper class="footer">
           
            <div className="row footer">
                <div className="col-md-4 pb-3">
                    <ul>
                        <li><h5>AM Software Solution GmbH</h5></li>
                        <li>Tannenstr. 15b • 82402 Seeshaupt</li>
                        <li>{t("footer-vat")}: DE333901394</li>
                        <li>{t("footer-court")}: Amtsgericht München</li>
                    </ul>
                    <div className="social-icons">
                        <a href="https://de.linkedin.com/company/am-ss" target="_blank" rel="noopener noreferrer">
                            <AiFillLinkedin size={24} />
                        </a>
                        <a href="https://www.xing.com/pages/amsoftwaresolutions" target="_blank" rel="noopener noreferrer">
                            <IoLogoXing size={24} />
                        </a>
                        <a href="https://github.com/am-software-solutions" target="_blank" rel="noopener noreferrer">
                             <AiFillGithub  size={24}/>
                        </a>
                    </div>
                   
                    <hr className="d-block d-sm-none" />
                </div>
                <div className="col-md-4 pb-3">
                    <ul>
                        <li><h5>&nbsp;</h5></li>
                        <li>{t("footer-register-number")}: HRB 259035</li>
                        <li>{t("footer-ceo")}: Marcel Hollenbach</li>
                        <li>Tel.: +49 (0)8801 915 274 8</li>
                    </ul>                   
                    <hr className="d-block d-sm-none" />
                </div>
                <div className="col-md-3 col-6">
                    <ul>
                        <li><a href="#services" style={{textDecoration: "inherit", color: "inherit"}}>{t("nav-service")}</a></li>
                        <li><a href="#motivation" style={{textDecoration: "inherit", color: "inherit"}}>{t("nav-motivation")}</a></li>
                        <li><a href="#about" style={{textDecoration: "inherit", color: "inherit"}}>{t("nav-about")}</a></li>
                        <li><a href="#career" style={{textDecoration: "inherit", color: "inherit"}}>{t("nav-career")}</a></li>
                        <li><a href="#contact" style={{textDecoration: "inherit", color: "inherit"}}>{t("nav-contact")}</a></li>
                        <li><a href="#contact" style={{textDecoration: "inherit", color: "inherit"}} onClick={privHandler}>{t("nav-privacy")}</a></li>
                    </ul>
                </div>
            </div>

        </SmallWrapper>
        </Container>
        </div>
    )
}

export default Footer
