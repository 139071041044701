import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SmallWrapper from "../common/SmallWrapper/SmallWrapper";
import "./Motivation.css";


const Motivaton = () => {
    const { t } = useTranslation();
    return(
        <SmallWrapper id="motivation">
        <p className='common-heading'>{t("section-subheading-motiviation")}</p>
        <h2 className='common-text'>{t("section-heading-motiviation")}</h2>
        <Row className='pt-5  pt-sm-0'>
            <div className='col-md-6'>
                <p>{t("content-motivation-1-para")}</p>
           </div>
           <div className='col-md-6'>
                <p>{t("content-motivation-2-para")}</p>
           </div>
        </Row>
   
    </SmallWrapper>
    )
}

export default Motivaton