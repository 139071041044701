import { Row } from 'react-bootstrap';
import "./SmallWrapper.css";


const  SmallWrapper = (props) => {
    return (
            <Row id={props.id} className={`small-wrapper ${props.class}`}>

                <div className='col-md-9 offset-md-3 col-xs-12'>
                 {props.children}   
                </div>
                
            </Row>
    )
    
}

export default SmallWrapper