import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import "./Modal.css"

function Modals({ show, handleClose, modalId }) {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Container >
              {modalId === 1 ? <><span className='modal-heading'>{t("job-modal-1-hdg")}</span>
                <h2 className='mb-3 modalTitle'>{t("job-modal-1-title")}</h2>
                <p className='mb-5'>{t("content-carrer-job-1")}</p></> :
                <></>}
              {modalId === 2 ? <><span className='modal-heading'>{t("job-modal-2-hdg")}</span>
                <h2 className='mb-3 modalTitle'>{t("job-modal-2-title")}</h2>
                <p className='mb-5'>{t("content-carrer-job-2")}</p></> :
                <></>}
              {modalId === 3 ? <><span className='modal-heading'>{t("job-modal-3-hdg")}</span>
                <h2 className='mb-3 modalTitle'>{t("job-modal-3-title")}</h2>
                <p className='mb-5'>{t("content-carrer-job-3")}</p></> :
                <></>}
            </Container>
          </Row>
          <Row className='mb-4'>
            <div className='col-lg-4 '>
              <h4 className='mb-4'>{t("job-modal-col-1-hdg")}</h4>
              <ul>
                {modalId === 1 ? <>
                  <li>{t("job-modal-1-col-1-1")}</li>
                  <li>{t("job-modal-1-col-1-2")}</li>
                  <li>{t("job-modal-1-col-1-3")}</li>
                  <li>{t("job-modal-1-col-1-4")}</li>
                  <li>{t("job-modal-1-col-1-5")}</li></> :
                  <></>}
                {modalId === 2 ? <>
                  <li>{t("job-modal-2-col-1-1")}</li>
                  <li>{t("job-modal-2-col-1-2")}</li>
                  <li>{t("job-modal-2-col-1-3")}</li>
                  <li>{t("job-modal-2-col-1-4")}</li>
                  <li>{t("job-modal-2-col-1-5")}</li></> :
                  <></>}
                {modalId === 3 ? <>
                  <li>{t("job-modal-3-col-1-1")}</li>
                  <li>{t("job-modal-3-col-1-2")}</li>
                  <li>{t("job-modal-3-col-1-3")}</li>
                  <li>{t("job-modal-3-col-1-4")}</li>
                  <li>{t("job-modal-3-col-1-5")}</li></> :
                  <></>}
              </ul>
            </div>
            <div className='col-lg-4'>
              <h4 className='mb-4'>{t("job-modal-col-2-hdg")}</h4>
              <ul>
              {modalId === 1 ? <>
                  <li>{t("job-modal-1-col-2-1")}</li>
                  <li>{t("job-modal-1-col-2-2")}</li>
                  <li>{t("job-modal-1-col-2-3")}</li>
                  <li>{t("job-modal-1-col-2-4")}</li>
                  <li>{t("job-modal-1-col-2-5")}</li></> :
                  <></>}
                {modalId === 2 ? <>
                  <li>{t("job-modal-2-col-2-1")}</li>
                  <li>{t("job-modal-2-col-2-2")}</li>
                  <li>{t("job-modal-2-col-2-3")}</li>
                  <li>{t("job-modal-2-col-2-4")}</li>
                  <li>{t("job-modal-2-col-2-5")}</li></> :
                  <></>}
                {modalId === 3 ? <>
                  <li>{t("job-modal-3-col-2-1")}</li>
                  <li>{t("job-modal-3-col-2-2")}</li>
                  <li>{t("job-modal-3-col-2-3")}</li>
                  <li>{t("job-modal-3-col-2-4")}</li>
                  <li>{t("job-modal-3-col-2-5")}</li></> :
                  <></>}
              </ul>
            </div>
            <div className='col-lg-4'>
              <h4 className='mb-4'>{t("job-modal-col-3-hdg")}</h4>
              <ul>
                <li>{t("job-modal-col-3-1")}</li>
                <li>{t("job-modal-col-3-2")}</li>
                <li>{t("job-modal-col-3-3")}</li>
                <li>{t("job-modal-col-3-4")}</li>
                <li>{t("job-modal-col-3-5")}</li>
                <li>{t("job-modal-col-3-6")}</li>
              </ul>
            </div>
          </Row>
          <Row>
            <div className="col-md-4 d-grid align-items-end ">
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Modals