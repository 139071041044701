import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './components/Navbar/Navbars';
import Header from './components/Header/Header';
import Section from './components/Section/Section';
import Motivaton from './components/Motivation/Motivation';
import Privacy from './components/Privacy/Privacy';
import Uber from './components/Uber/Uber';
import Career from "./components/Career/Career"
import Contact from "./components/Contact/Contact"
import { Container } from 'react-bootstrap';
import Footer from './components/Footer/Footer';
import { Suspense, useState } from 'react';


function App() {

  const [showPrivacy, setShowPrivacy] = useState(false);

  const changePriv = () => {
    setShowPrivacy(!showPrivacy);
  }

  return (
    <Suspense fallback={null}>

      {showPrivacy ?
        <>
          <Privacy />
        </>
        :
        <>
          <Navbar />
          <div className='main_container'>
            <div className="container_overlay">
              <Container>
                <Header />
                <Section />
              </Container>
            </div>
          </div>
          <Container>
            <div className='center_container' />
            <Motivaton />
            <Uber />
          </Container>

          <div className='bottom_bg_container'>
            <Container>
              <Career />
            </Container>
          </div>

          <div className='bottom_container'>
            <div className="container_overlay">
              <Contact />
              <Footer showPriv={changePriv} />
            </div>
          </div>
        </>

      }

    </Suspense>
  );
}

export default App;
