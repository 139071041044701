import React from "react";
import "./SectionHeading.css";
const SectionHeading = ({small, large}) => {
    return (
        <>
        <p className='common-heading'>{small}</p>
        <h1 className='common-text'>{large}</h1>
    </>)
}

export default SectionHeading