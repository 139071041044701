import SectionHeading from '../common/SectionHeading/SectionHeading';
import SmallWrapper from "../common/SmallWrapper/SmallWrapper"
import "./Career.css"
import Modal from '../Modal/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Career = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const handleClose1 = () => setShowModal1(false);
  const handleShow1 = () => setShowModal1(true);
  const handleClose2 = () => setShowModal2(false);
  const handleShow2 = () => setShowModal2(true);
  const handleClose3 = () => setShowModal3(false);
  const handleShow3 = () => setShowModal3(true);
  const { t } = useTranslation();
  const styleSetting = {
    paddingRight: 50
  }
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 993, // adjust as needed
        settings: {
          slidesToShow: 1,
          arrows: false
        },
      },
    ],
  };

  return (
    <SmallWrapper id="career">
      <SectionHeading small={t("section-subheading-carrer")} large={t("section-heading-carrer")} />
      <p>{t("content-carrer-1-para")}</p>
      <Slider {...sliderSettings}>
        <div className="jobCol">
          <h3 className="paddingTopBottom ">SW Engineering - Simulation</h3>
          <p>
            {t("content-carrer-job-1")} <span className="fst-italic">Software Engineering - Simulation</span>.
          </p>
          <button
            onClick={handleShow1}
            className="btn btn-narrow btn-outline-success"
          >
            Job Details
          </button>
        </div>
        <div className="jobCol">
          <h3 className="paddingTopBottom ">SW Engineering - Machine learning</h3>
          <p>
            {t("content-carrer-job-2")} <span className="fst-italic">Software Engineering - Machine learning</span>.
          </p>
          <button
            onClick={handleShow2}
            className="btn btn-narrow btn-outline-success"
          >
            Job Details
          </button>
        </div>
        <div className="jobCol">
          <h3 className="paddingTopBottom ">SW Engineering - Distributed computing</h3>
          <p>
            {t("content-carrer-job-3")} <span className="fst-italic">Software Engineering - Distributed computing</span>.
          </p>
          <button
            onClick={handleShow3}
            className="btn btn-narrow btn-outline-success"
          >
            Job Details
          </button>
        </div>
        <div className="jobCol">
          <h3 className="paddingTopBottom ">{t("content-carrer-job-initiative-hdg")}</h3>
          <p>
            {t("content-carrer-job-initiative")}
          </p>
        </div>
      </Slider>
      <Modal show={showModal1} handleClose={handleClose1} modalId={1} />
      <Modal show={showModal2} handleClose={handleClose2} modalId={2} />
      <Modal show={showModal3} handleClose={handleClose3} modalId={3} />
    </SmallWrapper>
  );
};

export default Career