import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../assets/img/logo.svg"
import "./Navbar.css";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Container } from 'react-bootstrap';


const Navbars = () => {
  const [scrolled, setScrolled] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('i18nextLng'));
  const { t } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    setCurrentLanguage(language);
  }

  useEffect(() => {
    window.onscroll = function () {
      // get the navbar element
      const navbar = document.querySelector(".sticky-backgroud");

      if (navbar) {
        // check if the user has scrolled down the page
        if (window.pageYOffset > 0 && !scrolled) {
          setScrolled(true);
          // reduce the width of the navbar
          navbar.classList.add("navbar-scrolled");
        } else if (window.pageYOffset <= 0 && scrolled) {
          setScrolled(false);
          // reset the width of the navbar
          navbar.classList.remove("navbar-scrolled");
        }
      }
    };
  }, [scrolled])

  return (

    <Navbar bg="" expand="lg" className='sticky-backgroud' fixed="top">
      <Container>
        <Navbar.Brand href="#home" className='col-sm-3 px-0'><img src={logo} className="logo" alt="LOGO" /> </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='col-sm-9 px-0'>
          <Nav className="me-auto gap">
            <Nav.Link href="#services" title={t("nav-service")}>{t("nav-service")}</Nav.Link>
            <Nav.Link href="#motivation" title={t("nav-motivation")}>{t("nav-motivation")}</Nav.Link>
            <Nav.Link href="#about" title={t("nav-about")}>{t("nav-about")} </Nav.Link>
            <Nav.Link href="#career" title={t("nav-career")}>{t("nav-career")}</Nav.Link>
            <Nav.Link href="#contact" title={t("nav-contact")}>{t("nav-contact")}</Nav.Link>
          </Nav>
          <Nav id="langNav" activeKey={currentLanguage}>
            <Nav.Link eventKey='de' onClick={() => { changeLanguage("de"); return false }}>DE</Nav.Link>
            <Nav.Link onClick={() => { return false }}>/</Nav.Link>
            <Nav.Link eventKey='en' onClick={() => { changeLanguage("en"); return false }}> EN</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


  );
}
export default Navbars;
